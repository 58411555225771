import React from "react";
import styles from "./Stake.module.css";
import Card1 from "./Card1";
import Card2 from "./Card2";
function Stake({ isDarkMode }) {
	return (
		<div className={isDarkMode ? styles.maindark : styles.main}>
			<div>
				<div className={styles.heading}>
					Why stake your <div className={styles.orange}>BONKI</div> coins?
				</div>
			</div>
			<div className={styles.box}>
				<Card1 />
				<Card2 />
			</div>
		</div>
	);
}

export default Stake;
