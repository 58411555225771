// ProgressBar.js
import React from "react";
import styles from "./Progress.module.css"; // Import the CSS module
import { Tooltip } from "antd";

const Progress = ({ percentage, ethShare }) => {
  return (
    <div className={styles.progressBarContainer}>
      {/* <div style={{textAlign:"left", alignContent:"left", alignSelf:"left"}}>
      {ethShare} BONKI
      </div>
      <div style={{justifyContent:'right'}}>
     {percentage}%
     </div> */}
     <Tooltip title={`${ethShare} BONKI`}>
      <div className={styles.progressBar} style={{ width: `${percentage}%` }}>
      {percentage}%
      </div>  
      </Tooltip>

    </div>
  );
};

export default Progress;
