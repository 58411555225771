import React from "react";
import styles from "./Token.module.css";
import { ReactComponent as Foot } from "../../assets/copyright.svg";
import { ReactComponent as Tokenr } from "../../assets/tokenright.svg";
import { ReactComponent as Tokenl } from "../../assets/tokenleft.svg";
import { CopyOutlined } from "@ant-design/icons";
import { message } from "antd";
function Token({ isDarkMode, setIsDarkMode }) {
  const copyToClipboard = () => {
    // Copy text to clipboard
    navigator.clipboard
      .writeText("0x90e4d3321288a7260A206DA769c28ea1BAF2918a")
      .then(
        () => {
          // Show success message
          message.success("Copied to clipboard!");
        },
        (err) => {
          // Show error message
          message.error("Failed to copy!");
        }
      );
  };
  return (
    <div
      className={isDarkMode ? styles.mainDark : styles.main}
      style={{ paddingTop: "8rem" }}
    >
      <div className={styles.heading}>Tokenomics!</div>
      <div className={styles.mid}>
        <Tokenl className={styles.mini1} />
        <div className={styles.heading3}>3%</div>
        <div className={styles.heading1}>BUY SELL</div>
      </div>
      <div className={styles.heading2}>TOTAL SUPPLY</div>
      <div className={styles.heading1} style={{marginTop:"1rem"}}>100,000,000,000,000</div>
      <Tokenr className={styles.mini} />
      <div className={styles.footer} style={{ zIndex: 7 }}>
        CA : 0x90e4d3321288a7260A206DA769c28ea1BAF2918a
        <CopyOutlined
          style={{ marginLeft: "1rem" }}
          onClick={copyToClipboard}
        />
      </div>
      <div>
        <Foot className={styles.foot} />
      </div>
    </div>
  );
}

export default Token;
