import React from "react";
import Vcard from "./Vcard";
import styles from "./Verse.module.css";
function Verse({ isDarkMode }) {
	const [isMobileView, setIsMobileView] = React.useState(
		window.innerWidth < 1024
	);

	React.useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth < 1024);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<div className={isDarkMode ? styles.maindark : styles.main}>
			<div className={styles.heading}>
				welcome to <br />
				<div className={styles.orange}>bonkiverse</div>
			</div>
			<div className={styles.heading1}>
				BonkiVerse is an ecosystem driven by the Bonki coin. Named after Bonk’s
				sister, the ecosystem offers integrations in more than 25 DeFi platforms
				and a fleet of utilities to engage and grow the Bonki family.
			</div>
			<div className={isMobileView ? styles.boxMobile : styles.box}>
				<Vcard
					title="BONKI STAKING DAPP"
					con="STAKING WITH BONKI IS YOUR TICKET TO PASSIVE INCOME AND ACTIVE PARTICIPATION IN SHAPING THE FUTURE OF THE BONKI COMMUNITY."
					link="click-here"
					icon="stake"
				/>
				<Vcard
					title="BONKI Swap"
					con="WIN TOGETHER, SWAP TOGETHER! BONKI OFFERS SEAMLESS CROSS-CHAIN SWAP THAT ARE FASTER, CHEAPER AND MORE EFFICIENT, BREAKING DOWN BARRIERS BETWEEN BLOCKCHAINS."
					link="coming-soon"
					icon="swap"
				/>
				<Vcard
					title="BONKI JACKPOT"
					con="BONKI'S LOTTERY SYSTEM ADDS A THRILLING TWIST TO YOUR EXPERIENCE. PARTICIPATE FOR A CHANCE TO WIN THE BIG PRIZE AND IMMERSE YOURSELF IN THE JOY OF UNEXPECTED WINS "
					link="coming-soon"
					icon="jackpot"
				/>
				<Vcard
					title="BONKI REVSHARE"
					con="BONKI INTRODUCES REVENUE-SHARING NFTS TO FATTEN YOUR WALLETS THESE EXCLUSIVE NFTS ARE MORE THAN JUST JPEGS. THEY LITERALLY ADD VALUE TO YOUR WALLET. OWN A PEICE OF THIS LIMITED NFT COLLECTION AND SECURE YOUR SLICE OF THE CAKE."
					link="coming-soon"
					icon="revshare"
				/>
			</div>
		</div>
	);
}

export default Verse;
