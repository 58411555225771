import React from "react";
import styles from "./Card.module.css";
import bt from "../../assets/bluetick.svg";
import p1 from "../../assets/partner1.jpeg";
import p2 from "../../assets/partner2.png";
import p3 from "../../assets/p3.jpeg";
function Card() {
  return (
    <div className={styles.cardContainer}>
      <div 
        className={styles.card}
        onClick={() => window.open("https://pinksale.finance/", "_blank")}
      >
        <img
          src={p1}
          alt="Pinksale"
          className={styles.background_image}
        ></img>

      </div>
      <div 
        className={styles.card}
        onClick={() => window.open("https://dexview.com/", "_blank")}
      >
        <img
          src={p2}
          alt="DexView"
          className={styles.background_image}
          style={{
            marginTop: "40px",
          }}
        ></img>

      </div>

    </div>
  );
}

export default Card;
