import React from "react";
import styles from "./Home.module.css";
import { ReactComponent as Svg } from "../../assets/girl.svg";
import { ReactComponent as Svg1 } from "../../assets/telegram.svg";
import { ReactComponent as Svg2 } from "../../assets/twitter.svg";
import { ReactComponent as Svg3 } from "../../assets/light.svg";
import { ReactComponent as Svg1d } from "../../assets/Svg1dark.svg";
import { ReactComponent as Svg2d } from "../../assets/Svg2dark.svg";
import Nav from "../Nav";
import DarkModeToggle from "react-dark-mode-toggle";
function Home({ isDarkMode, setIsDarkMode }) {
  const handleTwitterRedirect = () => {
    window.open("https://x.com/BonkiVip", "_blank");
  };

  const handleTelegramRedirect = () => {
    window.open("https://t.me/bonkicoin", "_blank");
  };

  return (
    <div className={isDarkMode ? styles.hellodark : styles.hello}>
      <Nav isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      <div className={styles.main}>
        <div className={styles.text}>
          <div className={styles.large}>
            <div className={styles.light}>
              <Svg3 className={styles.lightening} />
            </div>
            <div className={styles.mobile}>
              {" "}
              {/* <div className={styles.logo}>BONKI LOGO</div> */}
              <DarkModeToggle
                onChange={setIsDarkMode}
                checked={isDarkMode}
                size={80}
              />
            </div>
            <div style={{ paddingTop: "5rem" }}>
              <div className={styles.heading}>Enter the</div>
              <div className={styles.heading}>
                <div className={styles.orange}>Bonki</div> Verse
              </div>
            </div>
          </div>
          <div className={styles.sub}>
            <div>Where the Extraordinary Happens!</div>
            <div className={styles.social}>
              {isDarkMode ? (
                <>
                  {" "}
                  <Svg1d
                    onClick={handleTelegramRedirect}
                    className={styles.img}
                  />
                  <Svg2d
                    onClick={handleTwitterRedirect}
                    className={styles.img}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <Svg1
                    onClick={handleTelegramRedirect}
                    className={styles.img}
                  />
                  <Svg2
                    onClick={handleTwitterRedirect}
                    className={styles.img}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.svg}>
          <Svg className={styles.girl} />
        </div>
      </div>
    </div>
  );
}

export default Home;
