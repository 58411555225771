import React from "react";
import styles from "./Card.module.css";
import bt from "../../assets/bluetick.svg";
import p1 from "../../assets/p1.jpeg";
import p2 from "../../assets/p2.jpeg";
import p3 from "../../assets/p3.jpeg";
function Card() {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <img
          src={p1}
          alt="Background"
          className={styles.background_image}
        ></img>
        <div className={styles.content}>
          {/* <h2>Isekai Meta </h2>
          <span className={styles.verified_icon}>
            <img alt="img" src={bt} className={styles.bt}></img>
          </span> */}
        </div>
      </div>
      <div className={styles.card}>
        <img
          src={p2}
          alt="Background"
          className={styles.background_image}
        ></img>
        <div className={styles.content}>
          {/* <h2>Panopticon by teto</h2>
          <span className={styles.verified_icon}>
            <img alt="img" src={bt} className={styles.bt}></img>
          </span> */}
        </div>
      </div>{" "}
      <div className={styles.card}>
        <img
          src={p3}
          alt="Background"
          className={styles.background_image}
        ></img>
        <div className={styles.content}>
          {/* <h2>Oviators</h2>
          <span className={styles.verified_icon}>
            <img alt="img" src={bt} className={styles.bt}></img>
          </span> */}
        </div>
      </div>
    </div>
  );
}

export default Card;
