import React from "react";
import styles from "./Vcard.module.css";
import { useNavigate } from "react-router-dom";
function Vcard({ title, con, link, icon }) {
  const buttonPath = require(`../../assets/${link}.png`);
  const iconPath = require(`../../assets/${icon}-icon.png`);
  const navigate = useNavigate();
  return (
    <div className={styles.card}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.flower_icon}>
          <img
            style={{ height: "auto", width: "60px" }}
            src={iconPath}
            alt={icon}
          />
        </div>
        <div className={styles.link}>
          <img
            style={{ height: "auto", width: "95px", cursor: "pointer" }}
            src={buttonPath}
            onClick={
              link == "click-here" ? () => navigate("/staking") : () => {}
            }
            alt={link}
          />
        </div>
      </div>
      <h1>{title}</h1>
      <p>{con}</p>
    </div>
  );
}

export default Vcard;
