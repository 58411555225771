// StakingPage.js

import React, { useEffect, useState } from "react";
import StakingOption from "./StakingOption";
import styles from "./StakingPage.module.css";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, usePublicClient, useContractRead, useContractEvent } from "wagmi";
import { Table, Space, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { readContract, writeContract, waitForTransaction } from '@wagmi/core'
import { useReadContract } from 'wagmi'
import abi from '../../utils/abi.json'
import {BONKI, STAKING, STAKING_BLOCK} from '../../utils/config'
import { bscTestnet } from "viem/chains";
import { createPublicClient, fallback, http, parseAbiItem } from "viem";
import Icon from "@ant-design/icons/lib/components/Icon";
import { SelectOutlined } from "@ant-design/icons";

// import {config} from '../../config'

function StakingPage({ isDarkMode }) {
  const { address } = useAccount();
  const [decimals, setDecimals] = useState(9);
  const [balance, setBalance] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [stakeList, setStakeList] = useState([]);
  const [stakePercent, setStakePercent] = useState({'7':0,'28':0,'42':0});
  const [stakesPerDuration, setStakesPerDuration] = useState({'7':0,'28':0,'42':0});
  const [totalStakes, setTotalStakes] = useState(0);
  const { Column, ColumnGroup } = Table;
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const bnblogo = require("../../assets/bnb-logo.png");
  const logocoin = require("../../assets/logo-coin.png");
  const bonkilogo = require("../../assets/logo-3d-png.png");
  const bonkilogo_dark = require("../../assets/logo-3d-dark.png");

  useEffect(() => {
    async function getDecimals() {
      try{
        const abi= [{"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],"stateMutability":"view","type":"function"}]

        const result = await readContract({
          abi,
          address: BONKI,
          functionName: 'decimals',
        })
        setDecimals(Number(result));     
      }catch(e) {
        console.log("Error in getDecimals", e);
      }
    }
    getDecimals();
  }
  , [])


  async function getBalance() {
    try{
      const abi= [{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]

      const result = await readContract({
        abi,
        address: BONKI,
        functionName: 'balanceOf',
        args: [address]
      })
      setBalance(result);     
    }catch(e) {
      console.log("Error in getDecimals", e);
    }
  }
  async function getAvailableRewards() {
    try{
      const result = await readContract({
        abi,
        address: STAKING,
        functionName: 'totalRewards'
      })
      
      const availableRewards = Number(result)/10**decimals
      setAvailableRewards(availableRewards);

    }catch(e) {
      console.log("Error in getAvailableRewards", e);
    }
  }

  async function getStakes() {
    try{
      const client = createPublicClient({
        chain: bscTestnet,
        transport: fallback([http('https://bsc-testnet.publicnode.com')]),
      })

      const blockNumber = await client.getBlockNumber()
      // run looop from start block number to end block number
      let tempStakedLogs = []
      let tempUnstakedLogs = []
      for (let i = STAKING_BLOCK; i <= blockNumber; i+=9999) {
        const stakedLogs = await client.getLogs({
          address: STAKING,
          event: parseAbiItem('event Staked(uint256 id, address indexed user, uint256 amount, uint256 duration)'),
          args: {
            user: address
          },
          fromBlock: '0x'+(i).toString(16),
          toBlock: '0x'+(i+9999).toString(16),
          })

          const unstakedLogs = await client.getLogs({
            address: STAKING,
            event: parseAbiItem('event Unstaked(uint256 id, address indexed user, uint256 amount, uint256 rewardOrFees, bool successfullClaim)'),
            args: {
              user: address
            },
            fromBlock: '0x'+(i).toString(16),
            toBlock: '0x'+(i+9999).toString(16),
            })

          tempStakedLogs = tempStakedLogs.concat(stakedLogs)
          tempUnstakedLogs = tempUnstakedLogs.concat(unstakedLogs)
      }
      const stakedLogs = tempStakedLogs
      const unstakedLogs = tempUnstakedLogs

      console.log("stakedLogs", stakedLogs);
      console.log("unstakedLogs", unstakedLogs);

        let temp = []
        for (const log of stakedLogs) {
          // find element in unstakedLogs with same id
          const unstakeLog = unstakedLogs.find((unstakeLog) => unstakeLog.args.id === log.args.id)
          if(unstakeLog) {
            const {args, transactionHash, blockHash} = unstakeLog
            const {id, amount, rewardOrFees, successfullClaim} = args
            const trxnData = await client.getBlock({blockHash:blockHash})

            const stake = {
              id: Number(id),
              amount: Number(amount)/10**decimals,
              duration: Number(log.args.duration),
              rewardOrFees: Number(rewardOrFees)/10**decimals,
              successfullClaim,
              timestamp: trxnData.timestamp,
              transactionHash
            }
            temp.push(stake)
          }else{
            const {args, transactionHash, blockHash} = log
            const {id, amount, duration} = args
            const trxnData = await client.getBlock({blockHash:blockHash})
  
            const stake = {
              id: Number(id),
              amount: Number(amount)/10**decimals,
              duration: Number(duration),
              rewardOrFees: 0,
              successfullClaim: undefined,
              timestamp: trxnData.timestamp,
              transactionHash
            }
            temp.push(stake)
          }
        }
        setStakeList(temp)
        // // find an OR of the two arrays
        // const stakes = stakedLogs.concat(unstakedLogs)
        // console.log("stakes", stakes);
        // const stakesList = stakes.map(stake => {
        //   const {args, blockNumber, transactionHash} = stake
        //   const {id, amount, duration, rewardOrFees, successfullClaim} = args
        //   return {
        //     id,
        //     amount,
        //     duration,
        //     rewardOrFees,
        //     successfullClaim,
        //     blockNumber,
        //     transactionHash
        //   }
        // })

      
    } catch(e) {
      console.log("Error in getStakes", e);
    }
  }

  const updateStakePercent = () => {
    // sum of all stakes per duration which are not claimed
    const stakes = stakeList.filter(stake => stake.successfullClaim === undefined)
    console.log("stakes", stakes);
    // sum of all stakes per duration which are not claimed
    const stakesPerDuration = {'7':0,'28':0,'42':0}
    for (const stake of stakes) {
      if(stakesPerDuration[stake.duration]) {
        stakesPerDuration[stake.duration] += stake.amount
      }else{
        stakesPerDuration[stake.duration] = stake.amount
      }

    }
    setStakesPerDuration(stakesPerDuration)
    // sum of all stakes per duration which are not claimed
    let totalStakes = stakes.reduce((acc, stake) => {
      return acc + stake.amount
    }, 0)
    console.log("totalStakes", totalStakes);
    if(totalStakes === 0) {
      totalStakes = 1
    }
    setTotalStakes(totalStakes)
    // sum of all stakes per duration which are not claimed
    const stakePercent = {'7':0,'28':0,'42':0}
    for (const duration in stakesPerDuration) {
      console.log("duration & stakesPerDuration[duration]", duration, stakesPerDuration[duration]);
      stakePercent[duration] = (stakesPerDuration[duration]*100/totalStakes).toFixed(2)
    }
    console.log("stakePercent", stakePercent);
    setStakePercent(stakePercent)


    }

  
  useEffect(() => {
    getBalance();
    getAvailableRewards();
  },[address])
  
  useEffect(() => {
    getStakes();
  }
  ,[address])

  useEffect(() => {
    updateStakePercent();
  }
  ,[stakeList])

  const updateList = () => {
    getAvailableRewards();
    getBalance();
    getStakes();
    updateStakePercent();
    console.log("updateList");
  }

  const unstake = async(id) => {
    try{
      const result = await writeContract({
        abi,
        address: STAKING,
        functionName: 'unstake',
        args: [
          id
        ],
      })

      const waitRes = await waitForTransaction({hash: result.hash})
      console.log("waitRes", waitRes);
      toast.success("Unstaked Successfully")
      updateList();
    } catch(e) {
      console.log("Error in unstake", e);
      toast.error(e.message)
    }
  }


  return (
    <div className={styles.main}>
      <ToastContainer />
      <div className={styles.stakingPage}>
        <div className={styles.sideBar}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
            }}
          >
            <img
              src={isDarkMode ? bonkilogo_dark : bonkilogo}
              alt="BONKI"
              style={{ width: "50px", height: "50px" }}
            />
            <h2 style={{ margin: "1em", marginLeft: "0.3em" }}>
              Bonki's Staking DApp
            </h2>
          </div>
          <hr />
          <div className={styles.sideBarCard}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: 30, height: 30, marginRight: 10 }}>
                <img className={styles.cardImage} src={logocoin} alt="BONKI" />
              </div>
              <h3>$BONKI Rewards</h3>
            </div>
            <p>Total Rewards Left</p>
            <p>{Number(availableRewards).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BONKI</p>
            <p>Total BONKI Rewarded</p>
            <p>{(16000000000000-Number(availableRewards)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
             &nbsp;BONKI</p>
             {
                address && (
                  <p>Your Balance: {(Number(balance)/10**decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} BONKI</p>
                )
             }
          </div>
        </div>
        <div className={styles.content}>
          <div className={`${styles.contentHead} ${styles.section}`}>
            <div className={styles.titleBar}>
              <h1 className={styles.orange}>Staking</h1>
              {/* <button>Connect Wallet</button> */}
              <ConnectButton className={'orange-btn'} chainStatus={'none'} />
            </div>
            <div style={{ textAlign: "left" }}>
              <h4>Pool Size</h4>
              <p>
                Leverage the power of compounding by staking your $PAAL tokens
                and compounding rewards as they accrue.
              </p>
            </div>
          </div>
          <div className={`${styles.contentCards} ${styles.section}`}>
            <StakingOption days={7} ethShare={stakesPerDuration[7]} per={stakePercent[7]} decimals={decimals} updateList={updateList} />
            <StakingOption days={28} ethShare={stakesPerDuration[28]} per={stakePercent[28]}  decimals={decimals} updateList={updateList} />
            <StakingOption days={42} ethShare={stakesPerDuration[42]} per={stakePercent[42]} decimals={decimals} updateList={updateList} />
          </div>
          <div className={`${styles.contentRewards} ${styles.section}`}>
            <h2>My Stakes and Rewards</h2>
            <div
              style={{
                backgroundColor: "#fafafa",
                borderRadius: "1em",
                marginTop: "auto",
                minHeight: "150px",
                flex: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              
              {/* <button>Connect Wallet</button> */}
              {
                address ?
                <>
                  <Table style={{width:"100%"}} dataSource={stakeList}>

                    {/* <Column title="Id" dataIndex="id" key="id" /> */}
                    <Column title="Amount" dataIndex="amount" key="amount" />

                    <Column 
                      title="Timestamp" 
                      dataIndex="timestamp" 
                      key="timestamp" 
                      render={timestamp => (
                      // console.log("timestamp", timestamp, new Date(Number(timestamp)*1000).toLocaleString()),
                        new Date(Number(timestamp)*1000).toLocaleString()
                      )}
                    />
                    <Column title="Duration" dataIndex="duration" key="duration"
                      render={duration => (
                        <div>
                          {duration} Days
                        </div>
                      )}
                     />

                    <Column dataIndex="rewardOrFees" key="rewardOrFees" 
                      render={(_, record) => (
                        <>
                          {
                            record.successfullClaim == true ? 
                            <div style={{color:"green"}} >
                             + {record.rewardOrFees} BONKI
                            </div> 
                            : record.successfullClaim == false ? 
                            <div style={{color:"red"}} >
                             - {record.rewardOrFees} BONKI
                            </div> 
                            : null
                          }
                        </>
                      )}
                    />

                    <Column
                      title="Action"
                      key="action"
                      render={(_, record) => (
                        <Space size="middle">
                        {
                          record.successfullClaim === undefined ?(
                            (Number(record.timestamp)*1000 + record.duration*24*60*60*1000 < new Date().getTime()) ? //TODO: change to hrs
                          <button style={{cursor:"pointer"}} onClick={()=>unstake(record.id)}>Unstake</button>
                          :
                          <button style={{cursor:"pointer", backgroundColor:'red'}} onClick={()=>unstake(record.id)}>Withdraw</button>
                          ):
                          record.successfullClaim ? 
                          <button style={{backgroundColor:'grey'}}>Claimed</button> 
                          : 
                          <button style={{backgroundColor:'salmon'}}>Withdrew</button> 
                        }

                        <SelectOutlined onClick={window.open.bind(window, `https://testnet.bscscan.com/tx/${record.transactionHash}`, "_blank")}
                         />
                        </Space>
                      )}
                    />
                  </Table>
                </>
                : 
                <>
                  <p>Connect Your Wallet</p>
                  {openConnectModal && (
                    <button onClick={openConnectModal} className={styles.connectWallet} type="button">
                      Connect Wallet
                    </button>
                  )}
                </>
              }
              

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StakingPage;
