import React from "react";
import styles from "./AboutUp.module.css";
import bgdark from "../../assets/aboutwaleupdark.min.svg";
import bg from "../../assets/aboutwaleup.min.svg";
const AboutUp = React.memo(function AboutUp({ isDarkMode, setIsDarkMode }) {
  return (
    <div className={isDarkMode ? styles.maindark : styles.main}>
      {/* Uncomment and use the components as needed */}
      {/* <div className={styles.head}>What about Bonk Coin</div>
      <div className={styles.contentContainer}>
        <Right className={styles.img} style={{ position: "absolute", right: "0" }} />
        <Back className={styles.back} />
        <div className={styles.text}>
          Establishing the website and social community channels for GROK GIRL
          Token. Conducting and analyzing the market to identify target audience
          and competitors.
        </div>
      </div> */}
      {isDarkMode ? (
        <img
          src={bgdark}
          className={styles.img1}
          alt="Background"
          loading="lazy"
        />
      ) : (
        <img src={bg} className={styles.img1} alt="Background" />
      )}
    </div>
  );
});

export default AboutUp;
