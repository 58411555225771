import React from "react";
import styles from "./Card2.module.css";

function Card2() {
  const swapIcon = require("../../assets/swap-icon2.png");
  return (
    <div className={styles.card}>
      <div className={styles.card_header}>
        <div className={styles.flower}>
          <img
            src={swapIcon}
            alt="swap-icon"
            style={{ width: "60px", height: "auto" }}
          />
        </div>
        <h1 className={styles.left}>STAKEHOLDER BENEFITS</h1>
      </div>
      <div className={styles.card_body}>
        <p className={styles.left}>
          STAKED BONKI COINS ARE NOT JUST IDLE ASSETS; THEY BECOME ACTIVE
          PARTICIPANTS IN THE BONKI ECOSYSTEM. UNLOCK AN EXCLUSIVE REVENUE SHARE
          NFT WHITELIST AND ENJOY PRIORITY ACCESS TO UPCOMING BONKI INNOVATIONS.
        </p>
      </div>
    </div>
  );
}

export default Card2;
