import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import SmallNav from "./components/SmallNav";
import Stake from "./components/stake/Stake";
import Partners from "./components/partners/Partners";
import Market from "./components/market/Market";
import Verse from "./components/verse/Verse";
import Token from "./components/Token";
import AboutUp from "./components/AboutUp";
import StakingPage from "./components/staking/StakingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Replace this with your actual loading logic
    const timer = setTimeout(() => setLoading(false), 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading && (
        <div className="spinner-overlay">
          <Spin size="large" />
        </div>
      )}

      <div className={`content ${loading ? "blurred" : ""}`}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Home isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
                  <AboutUp
                    isDarkMode={isDarkMode}
                    setIsDarkMode={setIsDarkMode}
                  />
                  <Verse isDarkMode={isDarkMode} />
                  <Market isDarkMode={isDarkMode} />
                  <Partners isDarkMode={isDarkMode} />
                  <Stake isDarkMode={isDarkMode} />
                  <About
                    isDarkMode={isDarkMode}
                    setIsDarkMode={setIsDarkMode}
                  />
                  <SmallNav />
                  <Token
                    setIsDarkMode={setIsDarkMode}
                    isDarkMode={isDarkMode}
                  />
                </>
              }
            />

            <Route path="/staking" element={<StakingPage />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
