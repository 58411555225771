// StakingOption.js

import React, { useEffect, useState } from "react";
import styles from "./StakingOption.module.css";
import Progress from "./Progress";
import { useAccount } from "wagmi";
import { readContract, writeContract, waitForTransaction } from '@wagmi/core'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { Button, InputNumber } from "antd";
import abi from '../../utils/abi.json'
import { BONKI, STAKING } from '../../utils/config'
// import { config } from '../../config'
// import {config} from 'wagmi'

function StakingOption({ days, ethShare, per, decimals, updateList }) {
  
  const [stakeAmount, setStakeAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [apr, setApr] = useState(0);
  const { address } = useAccount();
  const [allowance, setAllowance] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  // console.log("allowance, approved", allowance, approved);

  useEffect(() => {
    async function getApr() {
      try{
        const result = await readContract({
          abi,
          address: STAKING,
          functionName: 'apr',
          args: [days]
        })
        setApr(Number(result));
      }catch(e) {
        console.log("Error in getApr", e);
      }
    }
    getApr();
  }
  , [])

  useEffect(() => {
    async function getAllowance() {
      try{
        const abi= [{"inputs":[{"internalType":"address","name":"","type":"address"},{"internalType":"address","name":"","type":"address"}],"name":"allowance","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}];

        const result = await readContract({
          abi,
          address: BONKI,
          functionName: 'allowance',
          args: [address, STAKING]
        })

        if(Number(result) > 0) {
          setAllowance(true);
        }
      }catch(e) {
        console.log("Error in getAllowance", e);
      }
    }
    getAllowance();
  }
  , [address])

  const stake = async() => {
    try{
      setLoader(true);
      const finStakeAmount = Number(stakeAmount) * 10 ** decimals;

      const result = await writeContract({
        abi,
        address: STAKING,
        functionName: 'stake',
        args: [
          finStakeAmount,
          days
        ],
      })

      const waitRes = await waitForTransaction({hash: result.hash})
      console.log("waitRes", waitRes);
      updateList();
      
      toast.success("Staked Successfully")
      setLoader(false);
    }catch(e) {
      console.log("Error in stake", e.message);
      setLoader(false);

      toast.error(e.message)
    }
  }
  const approve = async() => {
    try{
      setLoader(true);
      const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

      const result = await writeContract({
        abi: [{"inputs":[{"internalType":"address","name":"spender","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"approve","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"nonpayable","type":"function"}],
        address: BONKI,
        functionName: 'approve',
        args: [
          STAKING,
          MAX_UINT256,
        ],
      })

      setTimeout(() => {
        setLoader(false);
        window.location.reload();
      }, 5000);
    }catch(e) {
      console.log("Error in stake", e.message);
      toast.error(e.message)
    }
  }
  return (
    <div className={styles.stakingOption}>
      <ToastContainer />
      {/* <div className={styles.head}> */}
      <div className={styles.duration}>{days} Days</div>

      {/* <div className={styles.ethShare}>{ethShare}% ETH Rewards Share</div> */}
      {/* </div> */}

      <div className={styles.apr}>{apr}% APR</div>
      <div className={styles.progress}>
        <Progress percentage={per} ethShare={ethShare} />
      </div>
      {/* <button className={styles.connectWallet} onClick={}>Connect Wallet</button> */}
      {openConnectModal && (
        <button onClick={openConnectModal} className={styles.connectWallet} type="button">
          Connect Wallet
        </button>
      )}
      {
        address && (
          <>
          <div>
            <InputNumber placeholder="1000" style={{width:'65%'}} 
              onChange={(value) => setStakeAmount(value)}
             /> 
            &nbsp;
            {
              allowance ? (
                <Button className={styles.stakeBtn} type="button"
                style={{
                  width: '25%',
                }}
                onClick={stake}
                loading={loader}
                >
                  {loader?null:'Stake'}
              </Button>
              ):
              (
                <Button className={styles.stakeBtn} type="button"
                style={{
                  width: '30%',
                }}
                onClick={approve}
                loading={loader}
                >
                  {loader?null:'Approve'}
              </Button>
              )
            }

          </div>

          </>
        )
      }
    </div>
  );
}

export default StakingOption;
