import React from "react";
import styles from "./Card1.module.css";

function Card1() {
  const swapIcon = require("../../assets/swap-icon1.png");
  return (
    <div className={styles.card}>
      <div className={styles.card_header}>
        <div className={styles.flower}>
          <img
            src={swapIcon}
            alt="swap-icon"
            style={{ width: "60px", height: "auto" }}
          />
        </div>
        <h1 className={styles.left}>EARN REWARDS</h1>
      </div>
      <div className={styles.card_body}>
        <p className={styles.left}>
          STAKING YOUR BONKI COINS MEANS YOU'RE NOT JUST HOLDING; YOU'RE
          EARNING. ENJOY ATTRACTIVE HIGH APYS, MAKING YOUR BONKI INVESTMENT WORK
          FOR YOU.
        </p>
      </div>
    </div>
  );
}

export default Card1;
