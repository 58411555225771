import React from "react";
import styles from "./Market.module.css";
import Card from "./Card";
function Market({ isDarkMode }) {
	return (
		<div className={isDarkMode ? styles.maindark : styles.main}>
			<div>
				<div className={styles.heading}>
					Partners
				</div>
			</div>
			{/* <div className={styles.heading1}>
				Explore the <div className={styles.orange}>BONKI</div> NFT marketplace,
				a haven of unique digital collectibles. It's the only place where you
				can buy <div className={styles.orange}>BONKI</div> NFTs along with other
				exclusive collections.
			</div> */}
			<Card />
		</div>
	);
}

export default Market;
