import React, { useState, useEffect } from "react";
import styles from "./SmallNav.module.css";
import { ReactComponent as Logo } from "../../assets/svgviewer-output.svg";
import { useNavigate } from "react-router-dom";

const BoxComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/staking"); // Path to your staking page
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    console.log("clicked");
  };

  const scrollByPercentage = (percentage) => {
    const documentHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const targetScrollY = documentHeight * (percentage / 100);
    window.scrollTo({
      top: targetScrollY,
      behavior: "smooth",
    });
    setIsMenuOpen(false);
  };
  // const scrollByPercentage = (percentage) => {
  //   const scrollAmount = window.innerHeight * (percentage / 100);
  //   console.log({ scrollAmount });
  //   window.scrollTo({
  //     top: window.scrollY + scrollAmount,
  //     behavior: "smooth", // Optional: for smooth scrolling
  //   });
  //   setIsMenuOpen(false);
  // };

  return (
    <div className={styles.main}>
      <div
        className={`${styles.hamburger} ${isMenuOpen ? styles.active : ""}`}
        onClick={toggleMenu}
      >
        {/* Hamburger Icon */}
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div
        className={`${styles.customBox} ${
          isMenuOpen ? styles.customBoxOpen : ""
        }`}
      >
        <ul className={styles.jabjaiHeavy}>
          <li className={styles.link} onClick={() => scrollByPercentage(83)}>
            BONKI'S MISSION
          </li>
          <li className={styles.link} onClick={() => scrollByPercentage(93)}>
            TOKONOMICS
          </li>
          <li className={styles.link} onClick={() => scrollByPercentage(20)}>
            UTILITIES
          </li>
          <li>
            <Logo onClick={handleLogoClick} height={43} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BoxComponent;
