import React from "react";
import styles from "./Nav.module.css";
import { ReactComponent as Logo } from "../../assets/svgviewer-output.svg";
import { ReactComponent as DarkLogo } from "../../assets/logodark.svg";
import DarkModeToggle from "react-dark-mode-toggle";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// import About from "../About";

function Nav({ setIsDarkMode, isDarkMode }) {
  const scrollByPercentage = (percentage) => {
    const scrollAmount = window.innerHeight * (percentage / 100);
    console.log({ scrollAmount });
    window.scrollTo({
      top: window.scrollY + scrollAmount,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/staking"); // Path to your staking page
  };
  return (
    <nav
      className={styles.nav}
      style={{
        color: "white",
        paddingTop: "10px",
        width: "100%",
      }}
    >
      <ul
        className={styles.jabjaiHeavy}
        style={{
          listStyleType: "none",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <li
          className={styles.link}
          style={{ fontWeight: "bold" }}
          onClick={() => scrollByPercentage(730)}
        >
          BONKI'S MISSION
        </li>
        <li className={styles.link} onClick={() => scrollByPercentage(950)}>
          Tokenomics
        </li>
        <li className={styles.link} onClick={() => scrollByPercentage(380)}>
          utilities
        </li>
        <li>
          {isDarkMode ? (
            <DarkLogo style={{ cursor: "pointer" }} onClick={handleLogoClick} />
          ) : (
            <Logo style={{ cursor: "pointer" }} onClick={handleLogoClick} />
          )}
        </li>
        <li>
          <DarkModeToggle
            onChange={setIsDarkMode}
            checked={isDarkMode}
            size={80}
          />
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
